import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    representacion: {
      representacionId: null,
      representacionNombre: null,
      direccion: null,
      telefonoOficina: null,
      oficinaEmisora: null,
      porcentaje: null,
      porcentajeAsesor: null,
      porcentajeEjecutivo: null,
      foranea: null,
      latitud: null,
      longitud: null,
    },
    slides: [],
    Language: null,
    seeAll: true,
    ejecutivo: {},
    representaciones: [],
    Ejecutivos: []
  }
});

export default store;
